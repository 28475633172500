import React from "react"
import ViewJobs from "../../components/body/pages/careers/viewJobs"
import LayoutCareers from "../../components/layoutCareers"
import SEO from "../../components/seo"

const ViewAllJobs = () => (
  <LayoutCareers>
    <SEO
      canonical={"https://kuda.com/careers/view-jobs/"}
      title="Fintech Jobs at Kuda | Nigeria, UK, South Africa, Uganda | Kuda"
      description="The Kuda tribe is global. We're always looking out for skilled people with experience in iOS & Android engineering, DevOps, tech support & many more!"
    />
    <ViewJobs />
  </LayoutCareers>
)

export default ViewAllJobs
