import { Link, navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import Arrow from "../../../../assets/img/Arrow-primary.svg";
import NgFlag from "../../../../assets/img/NGflag.svg";
import UgFlag from "../../../../assets/img/UGflag.svg";
import UkFlag from "../../../../assets/img/UKflag.svg";
import SaFlag from "../../../../assets/img/SAflag.svg";
import GermanyFlag from "../../../../assets/img/germany.svg"
import SerbiaFlag from "../../../../assets/img/serbia.svg"
import BulgariaFlag from "../../../../assets/img/bulgaria.svg"
import EgyptFlag from "../../../../assets/img/egypt.svg"
import TurkeyFlag from "../../../../assets/img/turkey.svg"
import PortugalFlag from "../../../../assets/img/portugal.svg"
import CyprusFlag from "../../../../assets/img/cyprus.svg"
import UaeFlag from "../../../../assets/img/uae.svg"
import PolandFlag from "../../../../assets/img/poland.svg"
import { scrollToElement } from "../../../utility/utils";
import SearchBar from "./components/searchBar";
import { urls } from "../../../../network/url";
import EmptyState from "../../../../assets/img/empty-state-icon.svg"
import { useQueryParam, StringParam } from "use-query-params";
import Skeleton from "react-loading-skeleton";

const ViewJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loadData, setLoadData] = useState({
        loading: true,
        skeletonData: []
    });
    const [filterJobs, setFilterJobs] = useState([]);
    const [locationPath, setLocationPath] = useQueryParam("location", StringParam);


    const List = ({ jobRole, country, location, type, link }) => {
        const countryIcon = (country) => {
            switch (country) {
                case "South Africa":
                    return (
                        <span><img className="careers-flag-icon" src={SaFlag} /></span>
                    )
                case "United Kingdom":
                    return (
                        <span><img className="careers-flag-icon" src={UkFlag} /></span>
                    )
                case "Uganda":
                    return (
                        <span><img className="careers-flag-icon" src={UgFlag} /></span>
                    )
                case "Nigeria":
                    return (
                        <span><img className="careers-flag-icon" src={NgFlag} /></span>
                    )
                case "Germany":
                    return (
                        <span><img className="careers-flag-icon" src={GermanyFlag} /></span>
                    )
                case "Serbia":
                    return (
                        <span><img className="careers-flag-icon" src={SerbiaFlag} /></span>
                    )
                case "Bulgaria":
                    return (
                        <span><img className="careers-flag-icon" src={BulgariaFlag} /></span>
                    )
                case "Egypt":
                    return (
                        <span><img className="careers-flag-icon" src={EgyptFlag} /></span>
                    )
                case "Turkey":
                    return (
                        <span><img className="careers-flag-icon" src={TurkeyFlag} /></span>
                    )
                case "Portugal":
                    return (
                        <span><img className="careers-flag-icon" src={PortugalFlag} /></span>
                    )
                case "Cyprus":
                    return (
                        <span><img className="careers-flag-icon" src={CyprusFlag} /></span>
                    )
                case "United Arab Emirates":
                    return (
                        <span><img className="careers-flag-icon" src={UaeFlag} /></span>
                    )
                case "Poland":
                    return (
                        <span><img className="careers-flag-icon" src={PolandFlag} /></span>
                    )
                default:
                    return (
                        ""
                    )
            }
        }

        return (
            <div className="flex align-center career-job-list">
                <div onClick={() => navigate(link)} className="color-primary career-job-list-desc text-xlbold">
                    {jobRole}
                </div>
                <div className="flex align-center career-job-list-location">
                    <div className="mr-1">{countryIcon(country)}</div>
                    <div className="color-black">{location}</div>
                </div>
                <div className="career-job-list-type">
                    <span className="color-black">{type}</span>
                </div>
            </div>
        )
    }

    const ListSkeleton = () => {

        return (
            <div className="flex align-center career-job-list">

                <div className="career-job-list-desc skel--item">
                    <Skeleton width={`80%`} height={15} className="mr-auto"/>
                </div>

                <div className="flex align-center career-job-list-location no-border-skel skel--item">
                    <Skeleton width={25} height={25} className="mr-2" circle={true}/>
                    <Skeleton width={`70%`} height={15} containerClassName="center--skel-item"/>
                </div>

                <div className="career-job-list-type no-border-skel skel--item">
                    <Skeleton width={`100%`} height={15}/>
                </div>
            </div>
        )
    }

    const location = [
        "All",
        "Lagos, Nigeria",
        "Cape Town, South Africa",
        "London, United Kingdom",
        "Kampala, Uganda",
        "Poland",
        "Belgrade, Serbia",
        "Sofia, Bulgaria",
        "Cairo, Egypt",
        "Istanbul, Turkey",
        "Berlin, Germany",
        "Lisbon, Portugal",
        "Limassol, Cyprus",
        "Dubai, United Arab Emirates",
    ]

    const types = [
        "All",
        "Full-time",
        "Contract",
        "Internship"
    ]


    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, []);


    useEffect(() => {
        const jsonp = require('jsonp');
        setLoadData({ ...loadData, loading: true, skeletonData: [1,2,3,4,5,6,7] })
        
        jsonp(urls.getJobs, null, (err, data) => {
            if (err) {
                console.error(err.message);
            } else {
                    setJobs(data.jobs)
                if (!!locationPath) {
                    const filterByLocation = data.jobs.filter(job => job.country.includes(locationPath))
                    setFilterJobs(filterByLocation)
                } else {
                    setLocationPath("");
                    setFilterJobs(data.jobs)
                }
            }
            setLoadData({ ...loadData, loading: false, skeletonData: [] })
        }); 
    }, [])

    return (
        <div className="justify-center">
            <div className="flex justify-center mb-4">
                <div className="text-bold color-black">
                    <Link to="/careers">
                        Overview
                    </Link>
                </div>
                <img src={Arrow} className="careers-icon-margin" />
                <span className="color-primary text-bold">
                    All Openings
                </span>
            </div>

            <div className="kuda-career-bottom-margin3">
                <h1 className="careers-jobs-header kuda-career-bottom-margin2">
                    All Job Openings at Kuda
                </h1>
                <p className="text-center careers-p-mobile">Here are some of our available job openings.</p>
            </div>


            <SearchBar
                filterJobs={filterJobs}
                jobList={jobs}
                searchJobList={setFilterJobs}
                options={location}
                types={types}
            />

            <div>
                {loadData?.loading &&

                    loadData?.skeletonData?.length ?
                    loadData?.skeletonData.map(() => <ListSkeleton />) :

                    filterJobs?.length ?
                        filterJobs.map((item, i) => (
                            <List
                                key={i}
                                jobRole={item.title}
                                country={item.country}
                                location={item.city ? (`${item.city}, ${item.country}`) : (`${item.country}`)}
                                type={item.employment_type}
                                link={item.application_url}
                            />
                        )) :

                        (
                            <div className="flex align-center flex-column text-center">
                                <div className="careers-empty-state">
                                    <img src={EmptyState} />
                                </div>
                                <div className="color-black careers-empty-state kuda-career-bottom-margin3">
                                    <h4 className="text-xlbold">No results found</h4>
                                    <p>We couldn’t find what you searched for. You can check out other job openings at Kuda.</p>
                                </div>
                                {/* <div 
                                className="kuda-cta kuda-cta-long"
                                onClick={navigate("/")}>
                                View all openings
                            </div> */}
                            </div>
                        )
                }
            </div>

        </div>
    )
}

export default ViewJobs