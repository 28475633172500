import React, { useState, useRef, useCallback, useEffect } from "react"
import Jobs from "../../../../../assets/img/jobs.svg"
import Location from "../../../../../assets/img/location-point.svg"
import ChevronOpen from "../../../../../assets/img/chevron-open.svg"
import { useQueryParam, StringParam } from "use-query-params";
import { Img } from "react-image";

const SearchBar = ({ jobList, searchJobList, options, types }) => {
    const [showLocation, setShowLocation] = useState(false);
    const [type, setType] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [locationPath, setLocationPath] = useQueryParam("location", StringParam);
    const [inputField, setInputField] = useState({
        searchInputValue: "",
        typeInputValue: "",
    });
   


    const handleSubmit = (e) => e.preventDefault()

    const dropDownRef = useRef()

    const filterList = (search, location, type) => {
        let filteredList = jobList
        if (search) {    
            const searchInput = search.toLowerCase()
            const result = filteredList.filter(job => job.title.toLowerCase().includes(searchInput) || job.country.toLowerCase().includes(searchInput) || job.city.toLowerCase().includes(searchInput) || job.employment_type.toLowerCase().includes(searchInput))
            filteredList = result
        } 

        if (location) {
            if (location === "All")
            return filteredList
            const filterByLocation = filteredList.filter(job => job.country.includes(location))
            filteredList = filterByLocation
        }

        if (type) {
            if (type === "All")
            return filteredList
            const filterByType = filteredList.filter(job => job.employment_type.includes(type))
            filteredList = filterByType
        }

        return filteredList
    }

    const handleSearchChange = (e) => {
        setInputField({
            ...inputField,
            searchInputValue: e.target.value
        })
        if (!e.target.value) {
            const emptyState = filterList(locationPath, inputField.typeInputValue)
            return searchJobList(emptyState)
        }
        const result = filterList(e.target.value, locationPath, inputField.typeInputValue)
        
        searchJobList(result);
    }

    const toggleLocation = () => {
        setShowLocation(!showLocation);
    }

    const toggleType = () => {
        setType(!type)
    }

    const handleLocation = (e) => {
        setSelectedLocation(e);
        setShowLocation(false);
    
        const changeCountry = (e) => {
            switch (e) {
                case "Lagos, Nigeria":
                    return ("Nigeria")
                case "Cape Town, South Africa":
                    return ("South Africa")
                case "London, United Kingdom":
                    return ("United Kingdom")
                case "Kampala, Uganda":
                    return ("Uganda")
                case "Belgrade, Serbia":
                    return("Serbia")
                case "Sofia, Bulgaria":
                    return("Bulgaria")
                case "Cairo, Egypt":
                    return("Egypt")
                case "Istanbul, Turkey":
                    return("Turkey")
                case "Berlin, Germany":
                    return("Germany")
                case "Lisbon, Portugal":
                    return("Portugal")
                case "Limassol, Cyprus":
                    return("Cyprus")
                case "Dubai, United Arab Emirates":
                    return("United Arab Emirates")
                case "Poland":
                    return("Poland")
                default:
                    return ("")
            }
        }
        const query = changeCountry(e);
        setLocationPath(query);
        const filterByLocation = filterList(inputField.searchInputValue, query, inputField.typeInputValue)

        searchJobList(filterByLocation)
    }

    const handleType = (e) => {
        setSelectedType(e);
        setType(false);

        // if (e === "All")
        //     return searchJobList(jobList)
        setInputField({
            ...inputField,
            typeInputValue: e
        })
        const filterByType = filterList(inputField.searchInputValue, locationPath, e)

        searchJobList(filterByType)
    }

    const handleClickOutside = useCallback(
        (event) => {
            if (
                dropDownRef &&
                !dropDownRef.current.contains(event.target)
            ) {
                setShowLocation(false)
                setType(false)
            }
        },
        [],
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);


    return (
        <div ref={dropDownRef} className="flex align-center careers-search-bar">
            <div className="flex careers-search-input">
                <Img src={Jobs} className="mr-2" />
                <form className="" onSubmit={handleSubmit}>
                    <input
                        className="careers-inputfield"
                        value={inputField.searchInputValue}
                        type="text"
                        placeholder="Search job title or keyword"
                        onChange={handleSearchChange}
                    />
                </form>
            </div>

            <div className="careers-dropdown-mobile flex">
                <div className="flex flex-column career-location-dropdown">
                    <div onClick={toggleLocation} className="align-center career-jobview flex justify-between">
                        <div className="flex align-center">
                            <div className="mr-2">
                                <Img src={Location} />
                            </div>
                            <div>
                                {!selectedLocation && <p>Location</p>}
                                {selectedLocation && <p>{selectedLocation}</p>}
                            </div>
                        </div>
                        <div>
                            <Img src={ChevronOpen} />
                        </div>
                    </div>
                    {showLocation && (
                        <div className="careers-dropdown">
                            {options.map((value, index) => (
                                <div
                                    onClick={() => handleLocation(value)}
                                    key={value + index}
                                    className="career-location-list"
                                >
                                    {value}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex flex-column career-type-dropdown">
                    <div onClick={toggleType} className="align-center career-jobview flex justify-between">
                        <div>
                            {!selectedType && <p>Type</p>}
                            {selectedType && <p>{selectedType}</p>}
                        </div>
                        <div>
                            <img src={ChevronOpen} className="mr-2" />
                        </div>
                    </div>
                    {type && (
                        <div className="careers-dropdown careers-dropdown-2">
                            {types.map((value, index) => (
                                <div
                                    onClick={() => handleType(value)}
                                    key={value + index}
                                    className="career-location-list"
                                >
                                    {value}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default SearchBar